import React from 'react';
import Layout from '../layouts/Layout';
import MainButton from '../components/MainButton/MainButton';
import arrow from '../images/arrow.svg';

import menuItems from '../constants/menuItems';
import coachLogo from '../images/coach-logo.svg';

const JobDescription = ({ pageContext, location }: any) => {
	return (
		<Layout
			location={location}
			menuItems={menuItems}
			title={`${pageContext.title} | Coach USA Careers`}
			desc={`${pageContext.title} | Coach USA Careers`}
		>
			<section className={'bg-primary py-6'}>
				<div className={'container mx-auto px-5'}>
					<a
						href={'/jobs/'}
						className={
							'mb-12 flex max-w-[100px] flex-row text-white hover:text-white hover:underline'
						}
					>
						<img
							src={arrow}
							width={16}
							className={'mr-3 rotate-180'}
							alt={'Back Arrow.'}
						/>
						Back
					</a>
					<h1 className={'text-[40px] font-bold text-white '}>
						{pageContext.title}
					</h1>
				</div>
			</section>
			<section
				className={
					'bg-gradient-to-b from-[#DCE8E3] to-white px-3 py-6 pb-14 md:py-14'
				}
			>
				<div className={'container mx-auto'}>
					<div
						className={
							'mt-6 flex flex-row justify-between gap-5 pb-10 max-lg:flex-col-reverse'
						}
					>
						<div
							className={
								'job-description-content flex w-8/12 flex-col gap-8 max-lg:w-full'
							}
						>
							<h2 className={'my-5 text-[30px] font-bold'}>
								Job Description
							</h2>
							<p
								dangerouslySetInnerHTML={{
									__html: pageContext.description
								}}
							/>
							<a
								href={pageContext.url}
								className={'mt-6 max-w-[300px] ApplyButton ApplyButtonBottom'}
								target={'_blank'}
							>
								<MainButton>Apply Now</MainButton>
							</a>
						</div>
						<div
							className={
								'flex w-4/12 flex-col items-center self-start rounded-md bg-white p-6 shadow-2xl max-lg:w-full'
							}
						>
							<div className={'w-full'}>
								<img
									src={coachLogo}
									alt="Coach Logo"
									className="mx-auto my-6"
								/>
								<p className={'pb-3 text-xl font-bold '}>
									Location:
									<span
										className={
											'mx-2 inline text-lg font-normal'
										}
									>
										{pageContext.city}, {pageContext.state}
									</span>
								</p>
								<p className={'pb-3 text-xl font-bold '}>
									ReqId:
									<span
										className={
											'mx-2 inline text-lg font-normal'
										}
									>
										{pageContext.id}
									</span>
								</p>
								<p className={'pb-3 text-xl font-bold'}>
									{pageContext.salary}
								</p>
							</div>
							<div
								className={
									'flex w-full justify-center py-10 max-lg:hidden'
								}
							>
								<a href={pageContext.url} className={'ApplyButton ApplyButtonTop'} target={'_blank'}>
									<MainButton>Apply Now</MainButton>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default JobDescription;
